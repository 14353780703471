<script setup>
/**
 * @module UserAvatar
 * @description Component uses NaiveUI avatar element with some additional props
 * @see {@link https://www.naiveui.com/en-US/os-theme/components/avatar}
 */

import { NAvatar, NText, NH2 } from 'naive-ui'
import { useProfileStore } from '@/stores/profile'

const profileStore = useProfileStore()

defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
  round: {
    type: Boolean,
    default: false,
  },
  isBot: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <n-avatar
    v-if="show"
    lazy
    :size="size"
    :round="round"
    :src="isBot ? '../media/icons/open-ai-avatar.svg' : profileStore.userInfo.avatar"
    class="bg-primary-light-2 no-select"
    :class="[
      { 'cursor-pointer' : clickable }
    ]">
    <template v-if="!isBot && !profileStore.userInfo.avatar">
      <n-text
        v-if="size == 'small' || size == 'medium'"
        class="clr-white text-uppercase">
        {{ profileStore.userInitials }}
      </n-text>
      <n-h2
        v-if="size == 'large' || size == 'huge'"
        class="clr-white m-0 text-uppercase">
        {{ profileStore.userInitials }}
      </n-h2>
    </template>
  </n-avatar>
</template>
